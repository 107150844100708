<template>
    <div class="animated fadeIn">
        <b-card class="piplines-list">
            <b-card-header class="card-header-main">Create Access for project "{{ activeProject.name }}</b-card-header>
            <b-card-body>
                <b-row>
                    <b-col sm="4">
                        <b-form-group>
                            <label for="name">Member</label>
                            <b-form-input
                                v-model="$v.formData.member.$model"
                                type="text"
                                ref="member-name"
                                placeholder="Enter member e-mail adress"
                            ></b-form-input>
                            <Feedback
                                :state="validateRef('formData.member')"
                                invalid="This field is required and must be a valid email"
                                valid="E-mail is valid"
                            ></Feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <b-form-group>
                            <label for="role">Role</label>
                            <wit-select
                                class="invalid"
                                v-model="$v.formData.role.$model"
                                placeholder="Choose a role"
                                :options="['Viewer', 'Editor', 'Admin']"
                            ></wit-select>
                            <Feedback
                                :state="validateRef('formData.role')"
                                invalid="This field is required"
                                valid="Role is valid"
                            ></Feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <Feedback
                            v-if="!$v.$anyDirty"
                            :state="permissionError.state"
                            :invalid="permissionError.message"
                        ></Feedback>
                    </b-col>
                </b-row>
            </b-card-body>

            <b-card-footer>
                <b-button
                    :disabled="$v.formData.$invalid"
                    @click="createAccess"
                    type="submit"
                    size="sm"
                    variant="primary"
                >
                    <i class="fa fa-dot-circle-o"></i> Add access
                </b-button>
            </b-card-footer>
        </b-card>

        <YesNoModal
            v-model="showInvitationModal"
            title="Want to send an invite?"
            variant="warning"
            :yesAction="createInvite"
            :noAction="() => (showInvitationModal = false)"
            yes-variant="success"
            no-variant="danger"
        >
            <h5>
                We could not find the user You wanted to give the permission to. If you're sure you want to add it, You
                can send Them an invite to register on Witcloud and get it.
                <br />To do so, click <strong>Yes</strong>.
            </h5>
            <template v-slot:footer>
                <Feedback :state="invitationError.state" :invalid="invitationError.message"></Feedback>
            </template>
        </YesNoModal>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import YesNoModal from '@/components/Modals/YesNoModal.vue'

import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            formData: {
                member: null,
                role: null,
            },
            permissionError: {
                state: true,
                message: '',
            },
            invitationError: {
                state: null,
                message: '',
            },
            showInvitationModal: false,
        }
    },
    mixins: [validationMixin],
    validations: {
        formData: {
            member: {
                email,
                required,
            },
            role: {
                required,
            },
        },
    },
    components: {
        Feedback,
        Loading,
        YesNoModal,
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
    },
    methods: {
        createAccess() {
            this.$v.$reset()
            const data = {
                ...this.formData,
                projectId: this.$store.state.project.active.id,
            }
            const self = this
            this.$store.commit('loading/PROCESSING', `Creating Access`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/permissions`, data)
                .then(permission => {
                    this.$store.commit('loading/PROCESSED')
                    this.$projectRouter.push({
                        path: '/iam/permission',
                    })
                })
                .catch(exception => {
                    if (exception.response.status === 404) {
                        this.showInvitationModal = true
                    } else {
                        this.$errorHandler.report(exception, 'Could not create access')
                    }

                    this.permissionError.state = false
                    this.permissionError.message = exception.response.data.additionalInfo
                    self.$store.commit('loading/PROCESSED')
                })
        },
        createInvite() {
            this.$store.commit('loading/PROCESSING', `Sending an Invitation...`)
            this.axios
                .post(`${process.env.VUE_APP_NODE_API_HOST}/invitations/`, {
                    emailInvited: this.formData.member,
                    role: this.formData.role,
                    projectId: this.$store.state.project.active.id,
                })
                .then(response => {
                    this.showInvitationModal = false
                    this.$store.commit('loading/PROCESSED')
                    this.$projectRouter.push({
                        path: '/iam/permission',
                    })
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')

                    this.invitationError.state = false
                    this.invitationError.message = exception.response.data.data
                    this.$store.commit('loading/PROCESSED')
                    this.$errorHandler.report(exception, 'Could not create an invitation')
                })
        },
        validateRef(ref) {
            const keys = ref.split('.')
            const validateField = keys.reduce((a, c) => {
                return a[c]
            }, this.$v)

            return validateField.$dirty ? !validateField.$invalid : null
        },
    },
}
</script>
